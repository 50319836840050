import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  _,
  flowAPI,
) => {
  const { t } = flowAPI.translations;
  widgetBuilder.set({
    displayName: t('post-top-of-page.displayName'),
    nickname: 'TopOfPageWidget',
  });

  widgetBuilder.behavior().set({
    removable: false,
  });

  widgetBuilder.gfpp().set('mainAction1', { behavior: 'HIDE' });
};
