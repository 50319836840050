import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  _,
  flowAPI,
) => {
  const { t } = flowAPI.translations;
  widgetBuilder.set({
    displayName: t('post-categories.displayName'),
    nickname: 'Post CategoriesWidget',
  });

  widgetBuilder.gfpp().set('connect', { behavior: 'HIDE' });

  widgetBuilder.configureConnectedComponents(
    'button',
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.set({
        displayName: t('post-categories.gfpp.button'),
      });

      connectedComponentsBuilder
        .gfpp()
        .set('mainAction1', { behavior: 'HIDE' })
        .set('mainAction2', { behavior: 'HIDE' })
        .set('settings', { behavior: 'HIDE' })
        .set('design', { behavior: 'HIDE' });
    },
  );
};
