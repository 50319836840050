import { categoryHeaderAppDefId } from '../external/common/constants/apps';
import {
  BLOG_WIDGET_ID,
  CATEGORY_HEADER_WIDGET_ID,
} from '../external/common/constants/widgets';
import { getComponentRef } from '../external/common/services/sdk-utils';
import { EditorAppContext } from '../types/editor-app-context.type';
import { addWidgetPlugin } from './add-widget-plugin';

export const installCategoryHeader = async (context: EditorAppContext) => {
  const feedCompRef = await getComponentRef(context.sdk, BLOG_WIDGET_ID);
  const widgetSlotsByRef = await context.sdk.tpa.widgetPlugins.getWidgetSlots(
    context.appToken,
    {
      widgetRef: feedCompRef!,
    },
  );

  await addWidgetPlugin({
    widgetId: CATEGORY_HEADER_WIDGET_ID,
    slotCompRef: widgetSlotsByRef[0].compRef,
    context,
    appDefinitionId: categoryHeaderAppDefId,
  });
};
