import type { Experiments } from '@wix/yoshi-flow-editor';
import { WIX_SOCIAL_BLOG_SCOPE } from '../constants/experiments';

let experiments: Experiments;

const isEnabled = (experiment: string, value = 'true') =>
  experiments.get(experiment) === value;

const conduct = async (instance: Experiments) => {
  experiments = instance;
  await experiments.load(WIX_SOCIAL_BLOG_SCOPE);
};

const conductSingle = async (
  instance: Experiments,
  spec: string,
  fallbackValue?: string,
) => {
  experiments = instance;
  return experiments.conduct(spec, fallbackValue);
};

export default {
  isEnabled,
  conduct,
  conductSingle,
};
