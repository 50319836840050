import { get } from 'lodash';
import { IS_CONFIGURABLE_LINE_CLAMPING_ENABLED } from '@wix/communities-blog-client-common';
import type { EditorAppContext } from '../../../types/editor-app-context.type';
import { blogAppDefId } from '../constants/apps';
import {
  BLOG_WIDGET_ID,
  MY_POSTS_WIDGET_ID,
  POST_LIST_WIDGET_ID,
} from '../constants/widgets';

export const enableConfigurableLineClamping = async ({
  sdk,
  appToken,
  isADI,
}: EditorAppContext) => {
  if (isADI) {
    return;
  }

  const blogAppData = await sdk.tpa.app.getDataByAppDefId(
    appToken,
    blogAppDefId,
  );
  const blogAppComponents = await sdk.tpa.app.getAllCompsByApplicationId(
    appToken,
    blogAppData.applicationId,
  );

  const components = blogAppComponents.filter(({ widgetId }) =>
    [BLOG_WIDGET_ID, POST_LIST_WIDGET_ID, MY_POSTS_WIDGET_ID].includes(
      widgetId,
    ),
  );

  for (const component of components) {
    const compRef = await sdk.document.components.getById(appToken, {
      id: component.id,
    });
    const styleParams = await sdk.document.tpa.getStyleParams(appToken, {
      compRef,
    });
    const isLineClampingEnabled = get(
      styleParams,
      `booleans.${IS_CONFIGURABLE_LINE_CLAMPING_ENABLED}`,
      false,
    );

    if (!isLineClampingEnabled) {
      await sdk.document.tpa.setStyleParams(appToken, {
        compRef,
        styleParams: [
          {
            type: 'boolean',
            key: IS_CONFIGURABLE_LINE_CLAMPING_ENABLED,
            param: { value: true },
          },
        ],
      });
    }
  }
};
