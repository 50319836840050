import type { ElementData } from '@wix/platform-editor-sdk';
import {
  type ElementsPanelParams,
  openElementsPanel,
} from '../../blocks/common/elements-panel';
import type { EditorAppContext } from '../../types/editor-app-context.type';
import { POST_PAGE_SELECTORS } from './post-page-constants';

const COMPS_DATA = {
  postHeader: { role: POST_PAGE_SELECTORS.POST_HEADER, label: 'Header' },
  postContent: {
    role: POST_PAGE_SELECTORS.POST_CONTENT,
    label: 'Content',
  },
  postFooter: { role: POST_PAGE_SELECTORS.POST_FOOTER, label: 'Footer' },
};

export const openPostPageElementsPanel = async (
  context: EditorAppContext,
  widgetRef: ElementsPanelParams['widgetRef'],
) => {
  const { t } = context.flowAPI.translations;
  const elements: ElementData[] = [
    {
      label: t('post.elements.header'),
      identifier: { role: COMPS_DATA.postHeader.role },
      index: 0,
    },
    {
      label: t('post.elements.content'),
      identifier: { role: COMPS_DATA.postContent.role },
      index: 1,
    },
    {
      label: t('post.elements.footer'),
      identifier: { role: COMPS_DATA.postFooter.role },
      index: 2,
    },
  ];

  return openElementsPanel(context, {
    widgetRef,
    categoriesData: [],
    elementsData: elements,
  });
};
