import { EditorAppContext } from '../../../types/editor-app-context.type';
import { blogAppDefId } from '../constants/apps';
import { POST_WIDGET_ID } from '../constants/widgets';
import { getTextPresets, getColorPresets } from './magic-migration/style-utils';
import { patchSettings } from './settings';

export const savePostPageStyle = async ({
  sdk,
  appToken,
}: EditorAppContext) => {
  const [appInstance, textPresets, colorPresets] = await Promise.all([
    sdk.document.info.getAppInstance(appToken),
    sdk.theme.fonts.getMap(appToken).then(getTextPresets),
    sdk.theme.colors.getAll(appToken).then(getColorPresets),
  ]);

  const blogAppData = await sdk.tpa.app.getDataByAppDefId(
    appToken,
    blogAppDefId,
  );
  const blogAppComponents = await sdk.tpa.app.getAllCompsByApplicationId(
    appToken,
    blogAppData.applicationId,
  );
  const component = blogAppComponents.find(
    (_component) => _component.widgetId === POST_WIDGET_ID,
  );

  if (!component) {
    return;
  }

  const componentRef = await sdk.document.components.getById(appToken, {
    id: component.id,
  });
  const style = await sdk.tpa.getStyleParams(appToken, {
    compRef: componentRef,
  });
  patchSettings(appInstance, componentRef.id, 'draft', {
    textPresets,
    colorPresets,
    style,
  });
};
