import { type GetAppManifest } from '@wix/platform-editor-sdk';
import type { BlogSettings } from '@wix/ambassador-blog-settings-v2-blog-settings/types';
import { SEARCH_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import {
  EXPERIMENTS,
  IS_BLOG_MENU_SEARCH_ENABLED,
  IS_BLOG_MENU_SEARCH_ENABLED_MOBILE,
} from '@wix/communities-blog-client-common';
import {
  EXPERIMENT_LIVE_SITE_EDITING_DEPRECATION,
  EXPERIMENT_MIGRATE_CUSTOM_FEED_ON_EDITOR_READY,
} from '@wix/communities-blog-experiments';
import { updateBlogSettings } from '../../../blocks/common/update-blog-settings';
import { installCategoryHeader } from '../../../blocks/install-category-header';
import { installNewPostPage } from '../../../blocks/install-new-post-page';
import { handleBlogInstallTimeout } from '../../../editor/handle-blog-install-timeout';
import { OOI_EXPERIMENTS } from '../../../experiments';
import type { EditorAppContext } from '../../../types/editor-app-context.type';
import { blogAppDefId } from '../constants/apps';
import {
  APP_ACTION_EVENT,
  APP_ACTION_EVENTS,
  COMPONENT_ADDED_TO_STAGE,
  DELETE_BLOG_EVENT,
  INSTANCE_CHANGED,
  MANAGE_POSTS_EVENT,
  type EditorEvent,
} from '../constants/events';
import {
  MigrationId,
  OFFLINE_PAID_POST_MIGRATION_ID,
} from '../constants/migrations';
import { TPA_PAGE_ID_BLOG, TPA_PAGE_ID_POST } from '../constants/tpa-pages';
import { AUTOPILOT_USER_UUID } from '../constants/users';
import { BLOG_WIDGET_ID, POST_WIDGET_ID } from '../constants/widgets';
import {
  initBiService,
  openBlogPagesPanel,
  openPostPageSettings,
  setStyleParams,
} from './actions';
import getAppManifest from './app-manifest';
import concurrentEditing from './concurrent-editing';
import { migrateCustomFeed } from './custom-feed-migration/custom-feed-migration';
import { addEventListeners } from './event-listeners';
import experiments from './experiments';
import { getSiteMemberId } from './instance';
import { enableConfigurableLineClamping } from './line-clamping';
import { registerMemberPages } from './members-area/register-member-pages';
import { addPagesToMenu } from './menu';
import { migrateFeedDesignProps } from './migrate-feed-design-props';
import monitoring from './monitoring';
import pageService from './page';
import paidPostMigration from './paid-post-migration';
import { savePostPageStyle } from './post-page-style';
import { getComponentRef } from './sdk-utils';

const ADD_PANEL_DEEPLINK = 'addPanel';

export async function untilTimeout(
  callback: () => Promise<any>,
  message: string,
  duration = 15000,
): Promise<boolean> {
  const error = await Promise.race([
    callback().then(() => undefined as void),
    new Promise<string>((resolve) =>
      setTimeout(() => resolve(message), duration),
    ),
  ]);

  if (error) {
    monitoring.reportError(message);
    return true;
  }

  return false;
}

const installSiteSearch = async (context: EditorAppContext) => {
  const { sdk, appToken, isADI, flowAPI } = context;

  if (!flowAPI.experiments.enabled(OOI_EXPERIMENTS.INSTALL_SEARCH)) {
    return Promise.resolve();
  }

  if (isADI) {
    return Promise.resolve();
  }

  await concurrentEditing.withApproval(async () => {
    const isSearchInstalled = await sdk.document.tpa.isApplicationInstalled(
      appToken,
      { appDefinitionId: SEARCH_APP_ID },
    );

    if (isSearchInstalled) {
      return Promise.resolve();
    }

    const feedCompRef = await getComponentRef(sdk, BLOG_WIDGET_ID);
    const postPageCompRef = await getComponentRef(sdk, POST_WIDGET_ID);

    if (feedCompRef) {
      await sdk.document.tpa.setStyleParams(appToken, {
        compRef: feedCompRef,
        styleParams: [
          {
            type: 'boolean',
            key: IS_BLOG_MENU_SEARCH_ENABLED,
            param: { value: false },
          },
          {
            type: 'boolean',
            key: IS_BLOG_MENU_SEARCH_ENABLED_MOBILE,
            param: { value: false },
          },
        ],
      });
    }

    if (postPageCompRef) {
      await sdk.document.tpa.setStyleParams(appToken, {
        compRef: postPageCompRef,
        styleParams: [
          {
            type: 'boolean',
            key: IS_BLOG_MENU_SEARCH_ENABLED,
            param: { value: false },
          },
          {
            type: 'boolean',
            key: IS_BLOG_MENU_SEARCH_ENABLED_MOBILE,
            param: { value: false },
          },
        ],
      });
    }

    await untilTimeout(
      () =>
        sdk.document.tpa.add.application(blogAppDefId, {
          appDefinitionId: SEARCH_APP_ID,
        }),
      'Search installation timed out',
    );
  });
};

interface HandleEditorReadyParams {
  context: EditorAppContext;
  firstInstall: boolean;
}

type BlogSettingsPatch = Pick<
  BlogSettings,
  'blocksPostPage' | 'liveSiteEditingDeprecated'
>;

export const handleEditorReady = async ({
  context,
  firstInstall,
}: HandleEditorReadyParams) => {
  const instance = context.essentials.createExperiments({});
  await experiments.conduct(instance);

  concurrentEditing.init(context);

  await monitoring.toMonitored(
    'register-member-pages',
    concurrentEditing.withApproval(() => registerMemberPages(context)),
  );

  if (context.isADI) {
    return Promise.resolve();
  }

  if (!firstInstall) {
    const data = await context.sdk.document.tpa.app.getDataByAppDefId(
      context.appToken,
      blogAppDefId,
    );
    const components =
      await context.sdk.document.tpa.app.getAllCompsByApplicationId(
        context.appToken,
        data.applicationId,
      );

    const blogPages = await context.sdk.document.pages.getApplicationPages(
      context.appToken,
      { appDefinitionId: blogAppDefId } as any,
    );

    if (components === null || blogPages.length === 1) {
      await handleBlogInstallTimeout(context);
    }
  }

  return Promise.all([
    concurrentEditing.withApproval(async () => {
      if (
        !experiments.isEnabled(EXPERIMENT_MIGRATE_CUSTOM_FEED_ON_EDITOR_READY)
      ) {
        return Promise.resolve();
      }

      await migrateCustomFeed(context);
    }),
    concurrentEditing.withApproval(() => addPagesToMenu(context)),
    concurrentEditing.withApproval(async () => {
      await migrateFeedDesignProps(context);
      await setStyleParams(context);
      await enableConfigurableLineClamping(context);
    }),
    addEventListeners(context),
  ]);
};

export const handleBlogInstalled = async (context: EditorAppContext) => {
  const { sdk, appToken, essentials, installMembersArea } = context;

  const instance = essentials.createExperiments({});

  await experiments.conduct(instance);

  await initBiService(context);

  try {
    await savePostPageStyle(context);
  } catch (e) {}

  if (installMembersArea === false) {
    await installSiteSearch(context);
    return Promise.resolve();
  }

  const userId = await getSiteMemberId(sdk, appToken);
  const isAutopilot = userId === AUTOPILOT_USER_UUID;

  if (isAutopilot) {
    return Promise.resolve();
  }

  await installSiteSearch(context);

  const tpaSettingsPatch: BlogSettingsPatch = {};

  if (
    experiments.isEnabled(EXPERIMENTS.NEW_POST_PAGE) &&
    !context.flowAPI.environment.isADI
  ) {
    await monitoring.toMonitored(
      'install-new-post-page',
      installNewPostPage(context),
      false,
    );
  } else {
    tpaSettingsPatch.blocksPostPage = false;
  }

  if (experiments.isEnabled(EXPERIMENT_LIVE_SITE_EDITING_DEPRECATION)) {
    tpaSettingsPatch.liveSiteEditingDeprecated = true;
  }

  if (experiments.isEnabled(EXPERIMENTS.CATEGORY_HEADER)) {
    await monitoring.toMonitored(
      'install-category-header',
      installCategoryHeader(context),
      false,
    );
  }

  if (Object.keys(tpaSettingsPatch).length) {
    await updateBlogSettings(context, tpaSettingsPatch);
  }
};

export const openManagePostsDashboard = (context: EditorAppContext) => {
  let url = 'blog?referrer=app-manager';

  if (
    context.flowAPI.experiments.enabled(
      'specs.wixBlog.ManagePostsNavigateToPosts',
    )
  ) {
    url = 'blog/posts?referrer=app-manager';
  }

  context.sdk.editor.openDashboardPanel(context.appToken, {
    url,
    closeOtherPanels: false,
  });
};

const handleComponentAddedToSite = (context: EditorAppContext) =>
  concurrentEditing.withApproval(() => enableConfigurableLineClamping(context));

export const handleOnEvent = async (
  event: EditorEvent,
  context: EditorAppContext,
) => {
  if (!context.sdk) {
    return;
  }

  switch (event.eventType) {
    case MANAGE_POSTS_EVENT:
      return openManagePostsDashboard(context);
    case APP_ACTION_EVENT:
      switch (event.eventPayload?.actionId) {
        case APP_ACTION_EVENTS.CREATE_POST:
          context.sdk.editor.openDashboardPanel(context.appToken, {
            url: 'blog/create-post',
            closeOtherPanels: false,
          });
          break;
        case APP_ACTION_EVENTS.MANAGE_POSTS:
          openManagePostsDashboard(context);
          break;
        case APP_ACTION_EVENTS.OPEN_BLOG_PAGES_PANEL:
          await openBlogPagesPanel(context);
          break;
        case APP_ACTION_EVENTS.OPEN_POST_PAGE_SETTINGS:
          openPostPageSettings(
            context,
            context.flowAPI.translations.t(
              'app-manifest.app-descriptor.post-settings-title',
            ),
          );
          break;
        case APP_ACTION_EVENTS.OPEN_BLOG_ADD_PANEL:
          context.sdk.editor.deeplink.show(context.appToken, {
            type: ADD_PANEL_DEEPLINK,
            params: [blogAppDefId],
          });
          break;
        default:
          break;
      }
      break;

    case DELETE_BLOG_EVENT:
      return monitoring.toMonitored('delete-blog', deleteBlog(context));
    case COMPONENT_ADDED_TO_STAGE:
      return handleComponentAddedToSite(context);
    case INSTANCE_CHANGED:
      context.instance = event.eventPayload?.instance;
      break;
    default:
      break;
  }
};

async function deleteBlog(context: EditorAppContext) {
  const blogPage = await pageService.find({
    ...context,
    tpaPageId: TPA_PAGE_ID_BLOG,
  });

  if (!blogPage?.id) {
    const postPage = await pageService.find({
      ...context,
      tpaPageId: TPA_PAGE_ID_POST,
    });

    if (!postPage?.id) {
      return;
    }

    return context.sdk.document.pages.remove(context.appToken, {
      pageRef: { id: postPage.id, type: 'DESKTOP' },
    });
  }

  return context.sdk.document.pages.remove(context.appToken, {
    pageRef: { id: blogPage.id, type: 'DESKTOP' },
  });
}

export const handleGetAppManifest = ({
  sdk,
  appToken,
  flowAPI,
}: EditorAppContext): ReturnType<GetAppManifest> => {
  if (!sdk) {
    return {};
  }

  return getAppManifest(
    sdk.info.getSdkVersion(appToken).scriptSrc,
    appToken,
    flowAPI,
  );
};

export const handleMigrateAction = (
  context: EditorAppContext,
  migrationId: MigrationId,
) => {
  switch (migrationId) {
    case OFFLINE_PAID_POST_MIGRATION_ID:
      return paidPostMigration.migrateOffline(context);
    default:
      return Promise.resolve();
  }
};
