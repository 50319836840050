import {
  PRICING_PLANS,
  MEMBERS_AREA,
  WIX_STORES,
} from '@wix/app-definition-ids';
import { MA_APP_IDS } from '@wix/members-area-integration-kit';

import { membershipAppDefId } from '../constants/apps';
import monitoring from './monitoring';

// https://bo.wix.com/wix-docs/client/client-frameworks#offline-migration
//
// To trigger the migration, the service logs in to a autopilot user and opens the editor using puppeteer.
// The editor will be open twice - one time with the last saved revision and
// one time with the last published revision (or once if they are the same).
//
// Two revisions will be modified: 1. Last saved revision 2. Last published revision
const migrateOffline = async ({ sdk, appToken }) => {
  await monitoring.toMonitored(
    'paid-post-migration.migrate-offline',
    (async () => {
      await sdk.document.tpa.add.application(appToken, {
        appDefinitionId: membershipAppDefId,
      });

      const maxRetries = 10;
      let isSubscriptionsInstalled;

      for (let retryCount = 0; retryCount < maxRetries; retryCount++) {
        isSubscriptionsInstalled =
          await sdk.document.tpa.isApplicationInstalled(appToken, {
            appDefinitionId: MA_APP_IDS.MY_SUBSCRIPTIONS.appDefinitionId,
          });

        await new Promise((resolve) => setTimeout(resolve, 1000));

        if (isSubscriptionsInstalled) {
          break;
        }
      }

      monitoring.log('isSubscriptionsInstalled', {
        extra: { isSubscriptionsInstalled },
      });

      if (!isSubscriptionsInstalled) {
        const membersApi = await sdk.application.getPublicAPI('', {
          appDefinitionId: MEMBERS_AREA,
        });
        monitoring.log('membersApi exists', {
          extra: { membersApi: !!membersApi },
        });
        await membersApi?.registerMembersAreaApps(
          [MA_APP_IDS.MY_SUBSCRIPTIONS],
          PRICING_PLANS,
        );
        monitoring.log('membersApi registerMembersAreaApps executed');
        await membersApi?.installRegisteredApps(PRICING_PLANS);
        monitoring.log('membersApi installRegisteredApps executed');
        const storesApi = await sdk.application.getPublicAPI('', {
          appDefinitionId: WIX_STORES,
        });
        monitoring.log('storesApi exists', {
          extra: { storesApiExists: !!storesApi },
        });
      }
    })(),
  );
};

export default { migrateOffline };
