import type { ComponentRef } from '@wix/platform-editor-sdk';
import type { EditorAppContext } from '../types/editor-app-context.type';

interface OpenChangePresetsParams {
  context: EditorAppContext;
  componentRef: ComponentRef;
}

export const openChangePresets = ({
  context,
  componentRef,
}: OpenChangePresetsParams) =>
  context.sdk.editor.openPanel(
    'compPanels.panels.Widget.widgetPresetsPanel',
    { selectedComponent: [componentRef] },
    false,
  );
