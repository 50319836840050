import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  _,
  flowAPI,
) => {
  const { t } = flowAPI.translations;
  widgetBuilder.set({
    displayName: t('post-share-buttons.displayName'),
    nickname: 'Post Share ButtonsWidget',
  });

  widgetBuilder.configureConnectedComponents('shareButtons', (builder) => {
    builder.gfpp().set('mainAction2', { behavior: 'HIDE' });
  });
};
