import type { EditorSDK } from '@wix/platform-editor-sdk';
import { blogAppDefId } from '../external/common/constants/apps';
import {
  handleBlogInstalled,
  handleMigrateAction,
} from '../external/common/services/handlers';
import monitoring from '../external/common/services/monitoring';
import type { EditorActions } from '../types/editor-actions.type';
import { EditorAppContext } from '../types/editor-app-context.type';

interface HandleActionInternalParams {
  action: EditorActions;
  editorSDK: EditorSDK;
  context: EditorAppContext;
}

export const handleActionInternal = ({
  action,
  editorSDK,
  context,
}: HandleActionInternalParams) => {
  if (action.type === 'appInstalled') {
    if (action.payload.appDefinitionId !== blogAppDefId) {
      return;
    }

    return monitoring.toMonitored(
      'handle-blog-installed',
      handleBlogInstalled(context),
    );
  }

  if (action.type === 'migrate') {
    if (!action.payload.migrationId) {
      return;
    }

    return handleMigrateAction(
      { ...context, sdk: editorSDK },
      action.payload.migrationId,
    );
  }
};
