import { TinyColor } from '@ctrl/tinycolor';
import { get, map, partial } from 'lodash';
import {
  ColorParam,
  ColorStyleParam,
  FontStyleParamData,
  StyleParams,
} from '@wix/platform-editor-sdk';
import {
  BUTTON_COLOR,
  CARD_BACKGROUND_COLOR,
  CARD_BORDER_COLOR,
  DeepPartial,
  FeedPageSection,
  FEED_PAGE_SECTIONS,
  getCardBorderWidth,
  getLayoutName,
  getLayoutSettingsProps,
  getLayoutType,
  getPostLayoutParamByName,
  isLayoutTextOnImage,
  IS_FEED_DESIGN_PROPS_MIGRATED,
  LAYOUT_PG_GRID,
  LAYOUT_SIDE_BY_SIDE,
  LAYOUT_TYPES,
  LINK_AND_HASHTAG_COLOR,
  MixedLayoutType,
  NAVIGATION_TEXT_COLOR_ACTIVE,
  POST_DESCRIPTION_FONT,
  POST_TEXT_COLOR,
  POST_TITLE_FONT,
} from '@wix/communities-blog-client-common';
import { EditorAppContext } from '../../../types/editor-app-context.type';
import { BLOG_WIDGET_ID } from '../constants/widgets';
import { getColorColorNameByReference } from './magic-migration/style-utils';
import { getComponentRef } from './sdk-utils';

export const migrateFeedDesignProps = async ({
  sdk,
  appToken,
  isClassicEditor,
}: EditorAppContext) => {
  const feedCompRef = await getComponentRef(sdk, BLOG_WIDGET_ID);
  if (!feedCompRef) {
    return;
  }

  const styleParams = await sdk.document.tpa.getStyleParams(appToken, {
    compRef: feedCompRef,
  });

  if (isMigrated(styleParams)) {
    return;
  }

  const migratedStyleParams = getMigratedStyleParams({
    styleParams,
    isClassicEditor,
  });
  await sdk.document.tpa.setStyleParams(appToken, {
    compRef: feedCompRef,
    styleParams: migratedStyleParams,
  });
};

function isMigrated(styleParams: StyleParams) {
  return Boolean(get(styleParams, `booleans.${IS_FEED_DESIGN_PROPS_MIGRATED}`));
}

type ValuesByType = {
  font: { value: any };
  number: number;
  boolean: boolean;
  color: Partial<ColorParam> & DeepPartial<ColorStyleParam['param']['value']>;
};
type Type = keyof ValuesByType;

export function getMigratedStyleParams({
  styleParams,
  isClassicEditor = true,
}: {
  styleParams: StyleParams;
  isClassicEditor: boolean;
}) {
  const updates: {
    type: Type;
    key: string;
    param: any;
  }[] = [];
  const updateValue =
    <T extends Type>(type: T) =>
    (key: string, value: ValuesByType[T]) =>
      value && updates.push({ type, key, param: { value } });
  const updateFont = updateValue('font');
  const updateNumber = updateValue('number');
  const updateBoolean = updateValue('boolean');
  const updateColor = updateValue('color');

  const titleFont = styleParams.fonts?.[POST_TITLE_FONT];
  const descriptionFont = styleParams.fonts?.[POST_DESCRIPTION_FONT];
  const linkHashtagColor = styleParams.colors?.[LINK_AND_HASHTAG_COLOR] ?? {
    themeName: 'color-8',
  };
  const borderColor = styleParams.colors?.[CARD_BORDER_COLOR] ?? {
    themeName: 'color-2',
    opacity: '0.75',
  };
  const cardBackgroundColor = styleParams.colors?.[CARD_BACKGROUND_COLOR];
  const postTextColor = styleParams.colors?.[POST_TEXT_COLOR];
  const buttonColor = styleParams.colors?.[BUTTON_COLOR];

  const getFontColor = (
    layoutType: MixedLayoutType,
    param: keyof ReturnType<typeof getLayoutSettingsProps>,
  ): Partial<ColorParam> => {
    const layoutSettingsProps = getLayoutSettingsProps(layoutType);

    return isLayoutTextOnImage(layoutType) || !postTextColor
      ? { themeName: get(layoutSettingsProps, `${param}.startWithColor`) }
      : postTextColor;
  };

  const getFontSize = (
    section: FeedPageSection,
    layoutType: MixedLayoutType,
    key: 'title' | 'description',
  ) => {
    const layoutName = getLayoutName(layoutType);
    const styleParamKey = `numbers.${getPostLayoutParamByName(
      layoutName,
      section,
      key,
    )}FontSize`;
    const layoutSettingsProps = getLayoutSettingsProps(layoutType);

    return get(
      styleParams,
      styleParamKey,
      get(layoutSettingsProps, `${key}Font.startWithSize`),
    );
  };

  const setColor = (key: string, color: ValuesByType['color']) => {
    const tinyColor = new TinyColor(color.value);
    if (color.value && tinyColor.isValid) {
      updateColor(key, {
        rgba: tinyColor.toRgbString(),
        opacity: tinyColor.getAlpha(),
      });
    } else {
      const opacity = Number(color.opacity);
      const name = getColorColorNameByReference(color.themeName);
      name &&
        updateColor(key, {
          color: { name },

          ...(Number.isFinite(opacity) ? { opacity } : {}),
        });
    }
  };

  if (buttonColor && new TinyColor(buttonColor.value).isValid) {
    setColor(BUTTON_COLOR, {
      ...buttonColor,
      value: new TinyColor(buttonColor.value).setAlpha(1).toRgbString(),
    });
  }

  setColor(NAVIGATION_TEXT_COLOR_ACTIVE, linkHashtagColor);

  map(FEED_PAGE_SECTIONS, (section) => {
    const sectionLayoutType = getSectionLayoutType({
      styleParams,
      section,
      isClassicEditor,
    });
    const getGenericParam = partial(getPostLayoutParamByName, section);

    map(LAYOUT_TYPES, (layoutName, layoutType) => {
      const getParam = partial(getPostLayoutParamByName, layoutName, section);
      const getFontSizeByKey = partial(
        getFontSize,
        section,
        layoutType as unknown as MixedLayoutType,
      );

      if (titleFont) {
        const layoutTitleFont = getFontValue(
          titleFont,
          getFontSizeByKey('title'),
        );
        updateFont(getParam('titleFont'), layoutTitleFont);

        if (parseInt(layoutType, 10) === sectionLayoutType) {
          updateFont(getGenericParam('titleFont'), layoutTitleFont);
        }
      }

      if (descriptionFont) {
        const layoutDescriptionFont = getFontValue(
          descriptionFont,
          getFontSizeByKey('description'),
        );
        updateFont(getParam('descriptionFont'), layoutDescriptionFont);

        if (parseInt(layoutType, 10) === sectionLayoutType) {
          updateFont(getGenericParam('descriptionFont'), layoutDescriptionFont);
        }
      }
    });

    updateNumber(
      getGenericParam('borderWidth'),
      get(
        styleParams,
        `numbers.${getCardBorderWidth(sectionLayoutType, section)}`,
        1,
      ),
    );

    setColor(
      getGenericParam('titleColor'),
      getFontColor(sectionLayoutType, 'titleFont'),
    );
    setColor(
      getGenericParam('descriptionColor'),
      getFontColor(sectionLayoutType, 'descriptionFont'),
    );
    setColor(getGenericParam('borderColor'), borderColor);
    setColor(getGenericParam('linkHashtagColor'), linkHashtagColor);
    setColor(
      getGenericParam('backgroundColor'),
      cardBackgroundColor || { themeName: 'color-1' },
    );
    setColor(
      getGenericParam('TOI', 'backgroundColor'),
      cardBackgroundColor || { themeName: 'color-5' },
    );
  });

  updateBoolean(IS_FEED_DESIGN_PROPS_MIGRATED, true);

  return updates;
}

function getSectionLayoutType({
  section,
  styleParams,
  isClassicEditor,
}: {
  section: FeedPageSection;
  styleParams: StyleParams;
  isClassicEditor: boolean;
}): MixedLayoutType {
  const defaultLayout = isClassicEditor ? LAYOUT_SIDE_BY_SIDE : LAYOUT_PG_GRID;
  return get(
    styleParams,
    `numbers.${getLayoutType(section)}`,
    defaultLayout,
  ) as MixedLayoutType;
}

function getFontValue(
  font: FontStyleParamData,
  size: number,
): FontStyleParamData {
  return {
    ...font,
    size,
    preset: 'Custom',
  };
}
