import { EditorAppContext } from '../../../../types/editor-app-context.type';
import maIntegrationKit from '../ma-integration-kit';

const MEMBER_PAGES = [
  maIntegrationKit.MA_APP_IDS.BLOG_POSTS,
  maIntegrationKit.MA_APP_IDS.BLOG_COMMENTS,
  maIntegrationKit.MA_APP_IDS.BLOG_LIKES,
  maIntegrationKit.MA_APP_IDS.MY_DRAFTS,
];

const NEW_MEMBER_PAGES = [
  maIntegrationKit.MA_APP_IDS.BLOG_COMMENTS,
  maIntegrationKit.MA_APP_IDS.BLOG_LIKES,
  maIntegrationKit.MA_APP_IDS.MY_POSTS,
];

export async function registerMemberPages({
  isADI,
  isClassicEditor,
  isEditorX,
}: EditorAppContext) {
  const hasMemberPage = await hasAnyOfMemberPages([
    maIntegrationKit.MA_APP_IDS.MY_DRAFTS,
    maIntegrationKit.MA_APP_IDS.BLOG_POSTS,
  ]);

  if (hasMemberPage || isADI || (!isClassicEditor && !isEditorX)) {
    return maIntegrationKit.registerMembersAreaApps(MEMBER_PAGES);
  } else {
    return maIntegrationKit.registerMembersAreaApps(NEW_MEMBER_PAGES);
  }
}

async function hasAnyOfMemberPages(memberPages: any[]) {
  const installations = await Promise.all(
    memberPages.map((memberPage) =>
      maIntegrationKit.isMembersAreaAppInstalled(memberPage),
    ),
  );

  return installations.filter(Boolean).length > 0;
}
