import type {
  ComponentRef,
  ElementCategoryData,
  ElementData,
} from '@wix/platform-editor-sdk';
import { blogPostPageWidgetElementsActionsSrc69Evid144 } from '@wix/bi-logger-blog-data/v2';
import { EditorAppContext } from '../../types/editor-app-context.type';

export interface ElementsPanelParams {
  widgetRef: ComponentRef;
  elementsData: ElementData[];
  categoriesData: ElementCategoryData[];
}

export const openElementsPanel = async (
  { sdk, appToken, flowAPI }: EditorAppContext,
  panel: ElementsPanelParams,
) => {
  const biLogger = flowAPI.bi;
  return sdk.editor.openElementsPanel(appToken, {
    widgetRef: panel.widgetRef,
    categoriesData: panel.categoriesData,
    elementsData: panel.elementsData,
    addComponentHandler: async (elementIdentifier, compRef) => {
      biLogger?.report(
        blogPostPageWidgetElementsActionsSrc69Evid144({
          element: elementIdentifier.role,
          value: 'yes',
        }),
      );

      const componentRef =
        compRef ||
        (await getCollapsedRefComponentByRole(elementIdentifier.role));

      await sdk.components.refComponents.expandReferredComponent(appToken, {
        componentRef,
      });

      return sdk.application.livePreview.refresh(appToken, {
        shouldFetchData: false,
        source: 'AFTER_DB_CHANGE',
      });
    },
    removeComponentHandler: async (componentRef, elementIdentifier) => {
      biLogger?.report(
        blogPostPageWidgetElementsActionsSrc69Evid144({
          element: elementIdentifier.role,
          value: 'no',
        }),
      );
      await sdk.components.refComponents.collapseReferredComponent(appToken, {
        componentRef: await getCompToHide(componentRef),
      });
    },
  });

  async function getCollapsedRefComponentByRole(role: string) {
    const [widgetRefHost] = await sdk.components.getAncestors(appToken, {
      componentRef: panel.widgetRef,
    });
    const collapsedRefComponents =
      await sdk.components.refComponents.getCollapsedRefComponents(appToken, {
        componentRef: widgetRefHost,
        // @ts-expect-error temp until types are GAed
        includeInnerCollapsed: true,
      });
    const collapsedRefComponent = collapsedRefComponents.filter(
      (comp) => comp.role === role,
    );

    return collapsedRefComponent[0]?.componentRef;
  }

  async function getCompToHide(componentRef: ComponentRef) {
    const type = await sdk.components.getType(appToken, {
      componentRef,
    });
    return type.includes('AppWidget')
      ? (await sdk.components.getAncestors(appToken, { componentRef }))[0]
      : componentRef;
  }
};
